$(function () {
    $("#frm_emp_negocios").on("submit", function (e) {
        e.preventDefault();

        submitform($(this));

    });

    bs_input_file();
});


function submitform(form) {
    var formData = new FormData(form[0]);
    sendFormTo("sendform.php", formData, function (data) {
        if (data.status == false) {
            toastr.warning(data.info, data.title);
            scrollToElement($("#" + data.field));
            return false;
        }
        Swal.fire({
            icon: "success",
            title: "Formulário",
            text: "Enviado com sucesso",
        });
        $(form).trigger("reset");
    });
}
